
















.sltAllDepWrapper {
  .el-checkbox {
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .el-checkbox + .el-checkbox {
    margin-left: 0;
  }
}
