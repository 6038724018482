


























































































































































































































































































































































































































































































.noFile{
  .uploadShow{display: none;}
}
.noFileTips {
  text-align: center;
  line-height: 26px;
}
.el-form-item__content{
  .noFileTips{line-height: 36px;}
}

