











.container {
  position: relative;
  height: 100%;
}

.treeLeft {
  width: 220px;
  height: 100%;
  & ~ .treeRight {
    left: 228px;
  }
}

.treeRight {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden
}
